import * as React from 'react'
import PropTypes from 'prop-types'
import { VictoryChart, VictoryLine, VictoryVoronoiContainer } from 'victory'

class VelocityChart extends React.Component {
    parseHoverData (data) {
        return `awd: ${data.awd}, aws: ${data.aws}
            cog: ${data.cog}, speed: ${data.speed}
            twd: ${data.twd}, tws: ${data.tws}
            `
    }

    render () {
        return (
            <VictoryChart domainPadding={{ y: 10 }}
                          containerComponent={
                              <VictoryVoronoiContainer
                                  voronoiDimension='x'
                                  labels={d => this.parseHoverData(d.measurement)}
                                  onActivated={this.props.onMouseMove}
                              />
                          }
            >
                {this.props.data.map((velocityData, index) => (
                    <VictoryLine
                        key={index}
                        style={{ data: { stroke: this.props.colors[index].base }, labels: { fill: this.props.colors[index].base } }}
                        data={velocityData}
                    />
                ))}
            </VictoryChart>
        )
    }
}

VelocityChart.propTypes = {
    data: PropTypes.arrayOf(PropTypes.arrayOf(
        PropTypes.shape({
            x: PropTypes.number.isRequired,
            y: PropTypes.number.isRequired,
            measurement: PropTypes.object,
        })
    )).isRequired,
    colors: PropTypes.arrayOf(PropTypes.object).isRequired,
    onMouseMove: PropTypes.func.isRequired,
}

export default VelocityChart
