import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, withStyles } from '@material-ui/core'

const styles = () => ({
    input: {
        display: 'none',
    },
    container: {
        display: 'grid',
    }
})

class ImportPage extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            selectedFile: null,
            loaded: 0,
            pathId: null,
            buttonLabel: 'Wybierz Plik',
            validationDialogOpen: false,
        }
    }

    handlePathIdChanged (event) {
        this.setState({
            pathId: event.target.value
        })
    }

    handleSelectedFile (event) {
        this.setState({
            selectedFile: event.target.files[0],
            buttonLabel: event.target.files[0].name,
            loaded: 0,
        })
    }

    handleCloseDialog () {
        this.setState({ validationDialogOpen: false })
    }

    handleSubmit () {
        if (!this.state.selectedFile || !this.state.pathId) {
            this.setState({
                validationDialogOpen: true,
            })

            return
        }
        const data = new FormData()
        data.append('file', this.state.selectedFile, this.state.selectedFile.name)
        data.append('pathId', this.state.pathId)

        axios
            .post(`${process.env.REACT_APP_API_URL}/import`, data, {
                onUploadProgress: ProgressEvent => {
                    this.setState({
                        loaded: (ProgressEvent.loaded / ProgressEvent.total * 100),
                    })
                },
            })
            .then(res => {
                console.log(res.statusText)
            })
    }

    render () {
        const { classes } = this.props

        return (
            <Grid container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justify="center"
                  style={{ minHeight: '100vh' }}
            >
                <Grid item xs={3} style={{ width: '40%' }}>
                    <form onSubmit={this.handleSubmit} className={classes.container}>
                        <TextField
                            fullWidth
                            required
                            style={{ margin: 8 }}
                            helperText="Unikalny identyfikator"
                            label='Identyfikator trasy'
                            onChange={this.handlePathIdChanged.bind(this)}
                        />
                        <input
                            className={classes.input}
                            id="raised-button-file"
                            type="file"
                            onChange={this.handleSelectedFile.bind(this)}
                        />
                        <label htmlFor="raised-button-file">
                            <Button component="span" className={classes.button}>
                                {this.state.buttonLabel}
                            </Button>
                        </label>
                        <Button variant="contained" color="primary" className={classes.button} onClick={this.handleSubmit.bind(this)}>
                            Importuj
                        </Button>
                    </form>
                </Grid>
                <Dialog
                    open={this.state.validationDialogOpen}
                    keepMounted
                    onClose={this.handleCloseDialog.bind(this)}
                >
                    <DialogTitle>
                        {'Wymagane pola'}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Unikalny identyfikator trasy oraz plik z danym (CSV) jest wymagany
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseDialog.bind(this)} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        )
    }
}

ImportPage.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ImportPage)
