import React from 'react'
import PropTypes from 'prop-types'
import { GoogleMap, Marker, Polyline, withGoogleMap, withScriptjs } from 'react-google-maps'
import { compose, setPropTypes, withProps } from 'recompose'

export const TrackMap = compose(
    setPropTypes({
        zoom: PropTypes.number.isRequired,
        colors: PropTypes.objectOf(PropTypes.string).isRequired,
        center: PropTypes.shape({
            lat: PropTypes.number,
            lng: PropTypes.number,
        }).isRequired,
        markers: PropTypes.arrayOf(
            PropTypes.shape({
                lat: PropTypes.number,
                lng: PropTypes.number,
            })
        ).isRequired,
        markerIcons: PropTypes.objectOf(PropTypes.string).isRequired,
        pathCoordinates: PropTypes.objectOf(
            PropTypes.arrayOf(PropTypes.shape({
                lat: PropTypes.number,
                lng: PropTypes.number,
            })).isRequired
        ).isRequired,
    }),
    withProps({
        googleMapURL: 'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places',
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `400px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap
)((props) =>
    <GoogleMap
        defaultZoom={props.zoom}
        defaultCenter={props.center}
    >
        {props.markers.map((marker, index) =>{
            return <>
                <Polyline
                    key={`${index}-marker`}
                    path={props.pathCoordinates[index]}
                    geodesic={true}
                    options={{
                        strokeColor: props.colors[index],
                        strokeOpacity: 0.75,
                        strokeWeight: 2,
                    }}
                />
                <Marker
                    key={index}
                    position={marker}
                    icon={props.markerIcons[index]}
                />
            </>
            }
        )}
    </GoogleMap>
)
