import { cyan, grey } from '@material-ui/core/colors'

const white = '#fff'

export const primary1Color = '#04A7ED'
export const primary2Color = '#0288D1'
export const primary3Color = '#81D4FA'
export const accent1Color = '#142948'
export const textColor = 'rgba(0, 0, 0, .87)'
export const alternateTextColor = white

export const red = '#DA003E'

export const grey100 = '#F2F4F3'
export const grey300 = '#CBD2D8'
export const grey500 = '#A5AFB1'

export const theme = {
    fontFamily: '', // WeissenhofGrotesk is used in css
    palette: {
        primary: {
            light: primary1Color,
            main: primary2Color,
            dark: primary3Color,
        },
        secondary: {
            light: accent1Color,
            main: grey100,
            dark: grey500,
        },
        textColor,
        alternateTextColor,
        canvasColor: white,
        borderColor: grey300,
        disabledColor: grey[700],
        pickerHeaderColor: cyan[500],
        clockCircleColor: grey[600],
        shadowColor: grey[900],
    },
    toolbar: {
        backgroundColor: grey100,
        height: '48px',
    },
    tabs: {
        backgroundColor: white,
        textColor: textColor,
        selectedTextColor: textColor,
    },
    inkBar: {
        backgroundColor: primary1Color,
    },
    textField: {
        errorColor: red,
    },
    timePicker: {
        headerColor: primary1Color,
    },
    typography: {
        useNextVariants: true,
    },
}
