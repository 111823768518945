import React from 'react'
import { Link } from 'react-router-dom'
import { AppBar, Button, Toolbar, Typography } from '@material-ui/core'

export const Header = () => {
    return (
        <AppBar position="static">
            <Toolbar>
                <Typography variant="h6" color="inherit">
                Sailing Tracker
                </Typography>
                <Button
                    color="inherit"
                    component={Link}
                    to='/'
                >
                    Home
                </Button>
                <Button
                    color="inherit"
                    component={Link}
                    to='/import'
                >
                    Import
                </Button>
            </Toolbar>
        </AppBar>
    )
}
