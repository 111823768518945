import React, { Component } from 'react'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'
import { Header } from './components/Header'
import HomePage from './components/HomePage'
import { theme } from './theme'
import { Route, Switch } from 'react-router-dom'
import ImportPage from './components/ImportPage'

class App extends Component {
    render () {
        return (
            <MuiThemeProvider theme={createMuiTheme(theme)}>
                <Header />
                <Switch>
                    <Route exact path='/' component={HomePage} />
                    <Route exact path='/import' component={ImportPage} />
                </Switch>
            </MuiThemeProvider>
        )
    }
}

export default App
