import React from 'react'
import { Grid } from '@material-ui/core'
import { TrackMap } from './Track/TrackMap'
import VelocityChart from './Velocity/VelocityChart'
import WindChart from './Wind/WindChart'

class HomePage extends React.Component {
    directions = { ...Array.apply(null, { length: 360 }).map(Number.call, Number) }

    colors = [
        { base: 'gold', highlight: 'darkOrange' },
        { base: 'tomato', highlight: 'orangeRed' }
    ]

    constructor (props) {
        super(props)
        this.state = {
            measurements1: [],
            measurements2: [],
            maxTwdTws1: [],
            minTwdMaxTws1: [],
            maxTwdTws2: [],
            minTwdMaxTws2: [],
            windDomain1: [0, 100],
            windDomain2: [0, 100],
            minTws1: [],
            minTws2: [],
            avgTws1: [],
            avgTws2: [],
            maxTws1: [],
            maxTws2: [],
            pathCoordinates1: [],
            pathCoordinates2: [],
            marker1: null,
            marker2: null,
            mapCenter: null,
            velocityData1: [],
            velocityData2: [],
        }
    }

    componentDidMount () {
        this.getMeasurementsData()
    }

    getMeasurementsData () {
        const first = fetch(`${process.env.REACT_APP_API_URL}/zzz`)
            .then(response => response.json())
            .then(data => {
                const domainMax = Math.max.apply(Math, data.map(measurement => measurement.tws))
                const pathCoordinates = data.map(measurement => ({ lat: measurement.lat, lng: measurement.lng }))
                const newVelocityData = data.map((measurement, index) => ({ x: index, y: parseFloat(measurement.speed), measurement }))

                this.setState({
                    measurements1: data,
                    windDomain1: [0, domainMax],
                    pathCoordinates1: pathCoordinates,
                    velocityData1: newVelocityData
                })
            })

        const second = fetch(`${process.env.REACT_APP_API_URL}/mmm`)
            .then(response => response.json())
            .then(data => {
                const domainMax = Math.max.apply(Math, data.map(measurement => measurement.tws))
                const pathCoordinates = data.map(measurement => ({ lat: measurement.lat, lng: measurement.lng }))
                const newVelocityData = data.map((measurement, index) => ({ x: index, y: parseFloat(measurement.speed), measurement }))

                this.setState({
                    measurements2: data,
                    windDomain2: [0, domainMax],
                    pathCoordinates2: pathCoordinates,
                    velocityData2: newVelocityData
                })
            })

        Promise.all([first, second]).then(() => {
            const coordinates = this.state.pathCoordinates1.concat(this.state.pathCoordinates2)

            this.setState({
                mapCenter: coordinates[Math.round(coordinates.length / 2)]
            })
        })
    }

    getMaxTwdAndTws (key, data) {
        const keys = [key - 2, key - 1, key, key + 1, key + 2]
        const maxTwd = Math.round(Math.max.apply(Math, keys.map(k => data[k] ? data[k].twd : 0)))
        const maxTws = Math.round(Math.max.apply(Math, keys.map(k => data[k] ? data[k].tws : 0)))

        return Object.keys(this.directions).map(angle => {
            const width = maxTwd === +angle ? maxTws : 0

            return {
                maxTws: width,
                maxTwd: +angle
            }
        })
    }

    getMinTwdAndMaxTws (key, data) {
        const keys = [key - 2, key - 1, key, key + 1, key + 2]
        const minTwd = Math.round(Math.min.apply(Math, keys.map(k => data[k] ? data[k].twd : 0)))
        const maxTws = Math.round(Math.max.apply(Math, keys.map(k => data[k] ? data[k].tws : 0)))

        return Object.keys(this.directions).map(angle => {
            const width = minTwd === +angle ? maxTws : 0

            return {
                maxTws: width,
                minTwd: +angle
            }
        })
    }

    getMinTws (key, data) {
        const keys = [key - 2, key - 1, key, key + 1, key + 2]
        const allTwd = keys.map(k => data[k] ? data[k].twd : 0)
        const minTwd = Math.round(Math.min.apply(Math, allTwd))
        const maxTwd = Math.round(Math.max.apply(Math, allTwd))
        const minTws = Math.round(Math.min.apply(Math, keys.map(k => data[k] ? data[k].tws : 0)))

        return Object.keys(this.directions).map(angle => {
            const signedAngle = +angle

            return {
                y: signedAngle > minTwd && signedAngle < maxTwd ? minTws : 0,
                x: signedAngle
            }
        })
    }

    getMaxTws (key, data) {
        const keys = [key - 2, key - 1, key, key + 1, key + 2]
        const allTwd = keys.map(k => data[k] ? data[k].twd : 0)
        const minTwd = Math.round(Math.min.apply(Math, allTwd))
        const maxTwd = Math.round(Math.max.apply(Math, allTwd))
        const maxTws = Math.round(Math.max.apply(Math, keys.map(k => data[k] ? data[k].tws : 0)))
        const minTws = Math.round(Math.min.apply(Math, keys.map(k => data[k] ? data[k].tws : 0)))

        return Object.keys(this.directions).map(angle => {
            const signedAngle = +angle

            return {
                y: signedAngle > minTwd && signedAngle < maxTwd ? maxTws : 0,
                y0: signedAngle > minTwd && signedAngle < maxTwd ? minTws : 0,
                x: signedAngle
            }
        })
    }

    getAvgTws (key, data) {
        const keys = [key - 2, key - 1, key, key + 1, key + 2]
        const allTwd = keys.map(k => data[k] ? data[k].twd : 0)
        const average = arr => arr.reduce( ( p, c ) => p + c, 0 ) / arr.length;

        const minTwd = Math.round(Math.min.apply(Math, allTwd))
        const maxTwd = Math.round(Math.max.apply(Math, allTwd))
        const minTws = Math.round(Math.min.apply(Math, keys.map(k => data[k] ? data[k].tws : 0)))
        const avgTws = average(keys.map(k => data[k] ? data[k].tws : 0))

        return Object.keys(this.directions).map(angle => {
            const signedAngle = +angle

            return {
                y: signedAngle > minTwd && signedAngle < maxTwd ? avgTws : 0,
                y0: signedAngle > minTwd && signedAngle < maxTwd ? minTws : 0,
                x: signedAngle
            }
        })
    }

    handlerVelocityMouseMove (points) {
        if (points.length) {
            this.setState({
                marker1: this.state.pathCoordinates1[points[0].eventKey],
                minTws1: this.getMinTws(points[0].eventKey, this.state.measurements1),
                avgTws1: this.getAvgTws(points[0].eventKey, this.state.measurements1),
                maxTws1: this.getMaxTws(points[0].eventKey, this.state.measurements1),
                maxTwdTws1: this.getMaxTwdAndTws(points[0].eventKey, this.state.measurements1),
                minTwdMaxTws1: this.getMinTwdAndMaxTws(points[0].eventKey, this.state.measurements1),
            })

            if (points.length > 1) {
                this.setState({
                    marker2: this.state.pathCoordinates2[points[1].eventKey],
                    minTws2: this.getMinTws(points[1].eventKey, this.state.measurements1),
                    avgTws2: this.getAvgTws(points[1].eventKey, this.state.measurements1),
                    maxTws2: this.getMaxTws(points[1].eventKey, this.state.measurements1),
                    maxTwdTws2: this.getMaxTwdAndTws(points[1].eventKey, this.state.measurements2),
                    minTwdMaxTws2: this.getMinTwdAndMaxTws(points[1].eventKey, this.state.measurements2),
                })
            }
        }
    }

    render () {
        const {
            pathCoordinates1,
            pathCoordinates2,
            mapCenter,
            marker2,
            marker1,
            velocityData2,
            velocityData1,
            avgTws1,
            avgTws2,
            minTws1,
            minTws2,
            maxTws1,
            maxTws2,
            maxTwdTws1,
            maxTwdTws2,
            minTwdMaxTws1,
            minTwdMaxTws2,
            windDomain1,
            windDomain2,
        } = this.state
        return (
            <Grid container spacing={10}>
                <Grid item xs={12}>
                    {mapCenter && <TrackMap
                        pathCoordinates={{
                            0: pathCoordinates1,
                            1: pathCoordinates2,
                        }}
                        zoom={15}
                        center={mapCenter}
                        colors={{
                            0: this.colors[0].base,
                            1: this.colors[1].base,
                        }}

                        markers={[].concat(marker1, marker2)}
                        markerIcons={{
                            0: '/yellow-dot-md.png',
                            1: '/orange-dot-md.png',
                        }}
                    />}
                </Grid>
                <Grid item xs={6}>
                    {<VelocityChart
                        data={[velocityData1, velocityData2]}
                        colors={this.colors}
                        onMouseMove={this.handlerVelocityMouseMove.bind(this)}
                    />}
                </Grid>
                <Grid item xs={6}>
                    {<Grid container>
                        <Grid item xs={6}>
                            <WindChart
                                minTws={minTws1}
                                avgTws={avgTws1}
                                maxTws={maxTws1}
                                maxTwdTws={maxTwdTws1}
                                minTwdMaxTws={minTwdMaxTws1}
                                windDomain={windDomain1}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <WindChart
                                avgTws={avgTws2}
                                minTws={minTws2}
                                maxTws={maxTws2}
                                maxTwdTws={maxTwdTws2}
                                minTwdMaxTws={minTwdMaxTws2}
                                windDomain={windDomain2}
                            />
                        </Grid>
                    </Grid>}
                </Grid>
            </Grid>
        )
    }
}

export default HomePage
