import * as React from 'react'
import PropTypes from 'prop-types'
import { VictoryBar, VictoryChart, VictoryPolarAxis, VictoryTheme } from 'victory'

class WindChart extends React.Component {
    directions = {
        0: '0', 45: '45', 90: '90', 135: '135',
        180: '180', 225: '225', 270: '270', 315: '315'
    }

    render () {
        return (
            <VictoryChart
                polar
                theme={VictoryTheme.material}
                events={[{
                    childName: 'all',
                    target: 'data',
                    eventHandlers: {
                        onMouseOver: () => [
                            { target: 'labels', mutation: () => ({ active: true }) },
                            { target: 'data', mutation: () => ({ active: true }) }
                        ],
                        onMouseOut: () => [
                            { target: 'labels', mutation: () => ({ active: false }) },
                            { target: 'data', mutation: () => ({ active: false }) }
                        ]
                    }
                }]}
            >
                <VictoryPolarAxis
                    dependentAxis
                    labelPlacement='vertical'
                    style={{ axis: { stroke: 'none' } }}
                    domain={this.props.windDomain}
                    tickFormat={() => ''}
                />
                <VictoryPolarAxis
                    labelPlacement='perpendicular'
                    tickValues={Object.keys(this.directions).map(k => +k)}
                    tickFormat={Object.values(this.directions)}
                />
                <VictoryBar
                    style={{ data: {
                            fill: 'red',
                            width: 2
                        } }}
                    data={this.props.maxTwdTws}
                    y='maxTws'
                    x='maxTwd'
                />
                <VictoryBar
                    style={{ data: {
                            fill: 'black',
                            width: 2
                        } }}
                    data={this.props.minTwdMaxTws}
                    y='maxTws'
                    x='minTwd'
                />
                <VictoryBar
                    style={{ data: {
                            fill: '#00A2FF',
                            width: 1
                        } }}
                    data={this.props.minTws}
                />
                <VictoryBar
                    style={{ data: {
                            fill: '#00BE48',
                            width: 1
                        } }}
                    data={this.props.maxTws}
                />
                <VictoryBar
                    style={{ data: {
                            fill: '#BE0000',
                            width: 1
                        } }}
                    data={this.props.avgTws}
                />
            </VictoryChart>
        )
    }
}

WindChart.propTypes = {
    maxTwdTws: PropTypes.array.isRequired,
    avgTws: PropTypes.array.isRequired,
    minTws: PropTypes.array.isRequired,
    maxTws: PropTypes.array.isRequired,
    minTwdMaxTws: PropTypes.array.isRequired,
    windDomain: PropTypes.array.isRequired,
}

export default WindChart
